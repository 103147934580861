import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import Link from "../links/link"

const Root = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 200px;

  li {
    margin-bottom: 16px;
  }
  li:last-of-type {
    margin-bottom: 30px
  }
  li a {
    transition: ${p => p.theme.transitionDefault};
    color: ${p => p.theme.blueDark};
  }
  ul {
    a:hover {
      color: ${p => p.theme.green};
    }
  }

  @media (min-width: ${p => p.theme.screenS}) {
    max-width: 255px;
  }
  
  @media (min-width: ${p => p.theme.screenL}) {
    margin: 0;

    li {
    margin-bottom: 20px;
    }
    li:last-of-type {
      margin-bottom: 40px
    }
  })
`

const Title = styled.h2`
font-family: ${p => p.theme.baseFont};
font-weight: normal;
  text-transform: capitalize;
  display: block;
  color: ${p => p.theme.green};
  margin-bottom: 20px;
  font-size: 24px;

  @media (min-width: ${p => p.theme.screenL}) {
      margin-bottom: 40px;
      font-size: 28px;
    })
`

const Category = ({ data, theme }) => {
  const { category } = data.edges[0].node.data
  const seeAllQuestionslink = `/faq/${data.edges[0].node.data.category}`

  return (
    <Root>
      <Title>{category}</Title>
      <ul>
        {data.edges.map((item, index) => {
          const {
            uid,
            data: { category },
          } = item.node

          return (
            <li key={index}>
              <Link to={`/faq/${category}/${uid}/`}>
                {item.node.data.title.text}
              </Link>
            </li>
          )
        })}
      </ul>
      <Link to={seeAllQuestionslink} color={theme.greyLight}>
        See all questions
      </Link>
    </Root>
  )
}

Category.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(Category)
