import React from "react"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"

import Container from "../containers/container"
import Banner from "../containers/banner"
import Heading from "../typography/heading"
import Box from "../containers/box"
import Text from "../typography/text"
import Image from "../globals/image"
import { Absolute } from "../containers/position"
import MaxWidthAbsolute from "../containers/maxWidthAbsolute"

const FaqBannerLarge = ({ data, theme }) => {
  return (
    <Banner bg={theme.yellow}>
      <MaxWidthAbsolute>
        <Absolute growMid left={`20%`} top={0}>
          <Image
            fluid={data.profpicPink.childImageSharp.fluid}
            alt="TopTekkers football character, illustrated profile pic"
          />
        </Absolute>

        <Absolute grow left={-10} bottom={40}>
          <Image
            fluid={data.profpicOrange.childImageSharp.fluid}
            alt="TopTekkers football character, illustrated profile pic"
          />
        </Absolute>

        <Absolute grow right={-10} top={40}>
          <Image
            fluid={data.profpicRed.childImageSharp.fluid}
            alt="TopTekkers football character, illustrated profile pic"
          />
        </Absolute>

        <Absolute grow right={`10%`} bottom={-10}>
          <Image
            fluid={data.profpicBlue.childImageSharp.fluid}
            alt="TopTekkers football character, illustrated profile pic"
          />
        </Absolute>
      </MaxWidthAbsolute>

      <Container>
        <Box py={[100, null, 180]} textAlign="center">
          <Heading mx="auto" color={theme.white}>
            Frequently Asked Questions
          </Heading>

          <Text color={theme.white} p={0} mb={0}>
            Browse all topics below
          </Text>
        </Box>
      </Container>
    </Banner>
  )
}

FaqBannerLarge.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(FaqBannerLarge)
