import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Category from "./category"

const Root = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 50px;
  grid-template-columns: 100%;
  justify-content: space-between;

  @media (min-width: ${p => p.theme.screenXS}) {
    grid-template-columns: 46% 46%;
  }

  @media (min-width: ${p => p.theme.screenL}) {
    grid-row-gap: 72px;
    grid-template-columns: 32% 32% 32%;
  }
`

const CategoryList = ({ categories }) => {
  return (
    <Root>
      {categories.map((data, index) => {
        return <Category data={data} key={index} />
      })}
    </Root>
  )
}

CategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
}

export default CategoryList
